import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Homepage.js';
import Blog from './components/Blog.js'
import Projects from './components/Projects.js'
import Essays from "./components/Essays.js"

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/essays" element={< Essays />} /> 
        // Add other routes here
        <Route path="/blog" element={<Blog />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </Router>
  );
};

export default App;