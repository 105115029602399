import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image as BootstrapImage, Navbar, Nav, Dropdown } from 'react-bootstrap';
import { Github, Twitter, Linkedin } from 'react-bootstrap-icons';
import { TypeAnimation } from 'react-type-animation';
import './Homepage.css';
import profilePic from './assets/Profile.svg';
import profilePicPlaceholder from './assets/Profile.png'; // Low-res placeholder
import navbarIcon from './assets/Navbar.svg';
import 'bootstrap/dist/css/bootstrap.min.css';

function Homepage() {

  const [iconSize, setIconSize] = useState(window.innerWidth <= 420 ? 20 : 40);
  const [imageSrc, setImageSrc] = useState(profilePicPlaceholder); // Start with the placeholder

  const [isVisible, setIsVisible] = useState(false);
  const [hideCursor, setHideCursor] = useState(false);
  const fullText = "Hello! I’m Kole - a computer science major at Stanford and a professional magician. I’m exploring the intersection of technology, finance, and the creative economy.";
  const typingSpeed = 20;
  const typingDuration = fullText.length * typingSpeed / 1000; // in seconds

  const ref = React.createRef();


  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth <= 420 ? 20 : 40);
    };

    window.addEventListener('resize', handleResize);

    const img = new Image();
    img.src = profilePic;
    img.onload = () => setImageSrc(profilePic); // Switch to the high-res image when it's loaded

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


    return (
        <Container>

          <Row className="header d-flex align-items-center justify-content-between">
            <Col xs={4}>
              <Navbar>
                <Nav className="ml-md-0 mr-md-auto">
                  <Dropdown className="d-md-none">
                    <Dropdown.Toggle variant="link" className="custom-dropdown" id="dropdown-basic">
                      <BootstrapImage src={navbarIcon} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/essays">Blog</Dropdown.Item>
                      <Dropdown.Item href="https://kolelee.substack.com" target="_blank">Research</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Nav.Link as={Link} to="/essays" className="d-none d-md-block ml-auto custom-link">Blog</Nav.Link>
                  <Nav.Link href="https://kolelee.substack.com" target="_blank" className="d-none d-md-block custom-link">Research</Nav.Link>
                </Nav>
            </Navbar>
            </Col>

            <Col xs={4} className="text-center name-item d-md-none inter-font">
              Kole Lee
            </Col>

            <Col xs={4}>
            </Col>
          </Row>

          <Row className="picture-row justify-content-center no-gutters">
            <Col xs={8} className="image-container">
              <BootstrapImage className="profile-image" src={imageSrc} />
            </Col>
          </Row>

          {/* Add "Kole Lee" text below the profile picture, visible only on medium and larger screens */}
          <Row className="justify-content-center d-none d-md-flex name-row-mac">
            <Col xs={8} className="text-center header-mac inter">
              Kole Lee
            </Col>
          </Row>

          <Row className="text-row justify-content-center">
            <Col xs={10} lg={8} className="text-content inter-font">
              <TypeAnimation
                ref={ref}
                cursor={true} // Show the default cursor animation
                className={`typing-effect ${hideCursor ? 'hide-cursor' : ''}`}
                sequence={[
                  fullText,
                  () => {
                    setHideCursor(true); // Hide the cursor after the typing
                    setIsVisible(true); // Make the elements visible after the typing
                  }
                ]}
                wrapper="p"
                speed={{type: "keyStrokeDelayInMs", value: typingSpeed}}
                repeat={0}
              />
              <p className={`fade-in ${isVisible ? 'visible' : ''}`} style={{ animationDelay: `${typingDuration + 0.5}s` }}>Check out my <Link to="./essays">blog</Link>, where I share updates & reflections on my journey.</p>
              <p className={`fade-in ${isVisible ? 'visible' : ''}`} style={{ animationDelay: `${typingDuration + 1}s` }}>If you are interested in crypto, take a look at my <a href="https://kolelee.substack.com" target="_blank" rel="noopener noreferrer">research</a>, where I analyze and post theses on different areas of crypto.</p>
              <p className={`fade-in ${isVisible ? 'visible' : ''}`} style={{ animationDelay: `${typingDuration + 1.5}s` }}>I believe:</p>
              <p className={`custom-paragraph fade-in ${isVisible ? 'visible' : ''}`} style={{ animationDelay: `${typingDuration + 2}s` }}>- You can methodically excel at anything if you are passionate about it</p>
              <p className={`custom-paragraph fade-in ${isVisible ? 'visible' : ''}`} style={{ animationDelay: `${typingDuration + 2.5}s` }}>- NFTs might seem passé, but in ten years their volume will surpass volume of physical luxury/art.</p>
              <p className={`custom-paragraph fade-in ${isVisible ? 'visible' : ''}`} style={{ animationDelay: `${typingDuration + 3.5}s` }}>- The last jobs AI will fully abstract away are those of the human connection.</p>
            </Col>
          </Row>

          <Row className={`social-icons justify-content-center fade-in ${isVisible ? 'visible' : ''}`} style={{ marginTop: '20px', animationDelay: `${typingDuration + 4}s` }}>
            <Col xs={8} className="justify-content-center d-flex">
            <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="icon-link">
              <Github size={iconSize} className="mx-4 mx-md-10" />
            </a>
            <a href="https://twitter.com/kolelee_" target="_blank" rel="noopener noreferrer" className="icon-link">
              <Twitter size={iconSize} className="mx-4 mx-md-10" />
            </a>
            <a href="https://www.linkedin.com/in/kole-lee-b645641ab/" target="_blank" rel="noopener noreferrer" className="icon-link">
              <Linkedin size={iconSize} className="mx-4 mx-md-10" />
            </a>
            </Col>
          </Row>


        </Container>
    );
  }

export default Homepage;