import React from 'react';
import { Card } from 'react-bootstrap';

const Blog = () => {
  
  const blogs = [
    // Add your blogs here
    // Example: { title: 'Blog 1', description: 'This is blog 1', link: 'http://example.com' }
  ];

  return (
    <div>
      {blogs.map((blog, index) => (
        <Card key={index} style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{blog.title}</Card.Title>
            <Card.Text>
              {blog.description}
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default Blog;
