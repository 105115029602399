import React from 'react';
import { Card, Button } from 'react-bootstrap';

const Projects = () => {

  const projects = [
    // Add your projects here
    // Example: { title: 'Project 1', description: 'This is project 1', link: 'http://example.com' }
  ];

  return (
    <div>
      {projects.map((project, index) => (
        <Card key={index} style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{project.title}</Card.Title>
            <Card.Text>
              {project.description}
            </Card.Text>
            <Button variant="primary" href={project.link}>Go to Project</Button>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default Projects;
